label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    /* max-width: 350px !important; */
    padding: 40px 40px;
}

.card {
    margin: 0 auto 25px;
    margin-top: 20px;
}

.accordion {
    margin: 0 auto 25px;
    margin-top: 20px;
    /* max-width: 350px !important; */
    /* padding: 40px 40px; */
}

.accordion .card {
    margin: 0 auto 0px;
    margin-top: 0px;
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.row {
    margin-top: 15px;
}

.navbar {
    background-color: #e30613;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: white;
    background: rgba(0, 0, 0, .15);
    border-radius: 3px;
}

.nav-link {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.table>:not(caption)>*>*.gruen {
    background-color: #99FF33;
    width: 30px;
}

.table>:not(caption)>*>*.rot {
    background-color: #FA5858;
    width: 30px;
}

.table>:not(caption)>*>*.orange {
    background-color: #FFCC33;
    width: 30px;
}

.table>:not(caption)>*>*.abreisetag {
    background-color: #2E2EFE;
    width: 30px;
}

.table>:not(caption)>*>*.anreisetag {
    background-color: #2EFEF7;
    width: 30px;
}

.table>:not(caption)>*>*.wachtag {
    background-color: #2E9AFE;
    width: 30px;
}

.table>:not(caption)>*>*.wf {
    background: linear-gradient( 135deg, yellow, yellow 33%, #99FF33 33%, #99FF33 100%);
    background-size: 100% 100%;
    width: 30px;
}

.table>:not(caption)>*>*.swf {
    background: linear-gradient( 135deg, orange, orange 33%, #99FF33 33%, #99FF33 100%);
    background-size: 100% 100%;
    width: 30px;
}

.table>:not(caption)>*>*.bf {
    background: linear-gradient( 135deg, #2E9AFE, #2E9AFE 33%, #99FF33 33%, #99FF33 100%);
    background-size: 100% 100%;
    width: 30px;
}

.table {
    width: auto;
}

table {
    font-size: 1vw;
}

a .fa-envelope {
    color: #000;
}

.colorbox {
    float: left;
    height: 18px;
    width: 14px;
    margin-right: 3.5em;
    margin-left: 5em;
    margin-top: 0px;
}

.table>:not(caption)>*>*.row {
    margin-top: -12px;
}

.colorbox.gruen {
    background-color: #99FF33;
}

.colorbox.orange {
    background-color: #FFCC33;
}

.colorbox.rot {
    background-color: #FA5858;
}

.colorbox.anreise {
    background-color: #2EFEF7;
}

.colorbox.wachtag {
    background-color: #2E9AFE;
}

.colorbox.abreise {
    background-color: #2E2EFE;
}